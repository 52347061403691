import React from 'react'
import PropTypes from 'prop-types'
import { Trans } from 'gatsby-plugin-react-i18next'
import {
  CustomizationOption,
  Subheader,
  FrameProductOptions,
  OutOfStock,
  RedSubheader,
  Bogo,
  LimitedEdition,
} from '../FrameProductForm/styles'
import ColorSelectionItem from '../ColorSelectionItem'

const FrameColorSelection = ({
  isOutOfStock,
  DISPLAY_BOGO,
  FRAME_VARIANTS,
  LIMITED_EDITION_VARIANTS,
  selectedColor,
  handleColorSelection,
  contentfulCTAOverrides,
}) => (
  <CustomizationOption>
    <Subheader>
      <Trans>Select frame color</Trans>
    </Subheader>
    {isOutOfStock && (
      <OutOfStock>
        <Trans>Out of stock</Trans>
      </OutOfStock>
    )}
    <FrameProductOptions>
      {FRAME_VARIANTS.map((variant) => (
        <ColorSelectionItem
          key={variant}
          variant={variant}
          onSelectColor={handleColorSelection}
          isOutOfStock={
            contentfulCTAOverrides.edges[0].node[`en_10_frame_${variant}`] === 'Out of Stock'
          }
          isSelected={selectedColor === variant}
        />
      ))}
    </FrameProductOptions>
    <LimitedEdition>
      <Trans>Limited Edition</Trans>
    </LimitedEdition>
    <FrameProductOptions>
      {LIMITED_EDITION_VARIANTS.map((variant) => (
        <ColorSelectionItem
          key={variant}
          variant={variant}
          onSelectColor={handleColorSelection}
          isOutOfStock={
            contentfulCTAOverrides.edges[0].node[`en_10_frame_${variant}`] === 'Out of Stock'
          }
          isSelected={selectedColor === variant}
        />
      ))}
    </FrameProductOptions>
  </CustomizationOption>
)

FrameColorSelection.propTypes = {
  isOutOfStock: PropTypes.bool,
  DISPLAY_BOGO: PropTypes.bool,
  FRAME_VARIANTS: PropTypes.array.isRequired,
  LIMITED_EDITION_VARIANTS: PropTypes.array.isRequired,
  selectedColor: PropTypes.string.isRequired,
  handleColorSelection: PropTypes.func.isRequired,
  contentfulCTAOverrides: PropTypes.object.isRequired,
}

export default FrameColorSelection
