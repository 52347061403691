import React from 'react'
import PropTypes from 'prop-types'
import { Trans } from 'gatsby-plugin-react-i18next'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Subheader, FrameProductOptions } from '../FrameProductForm/styles'
import ColorSelectionItem from '../ColorSelectionItem'
import {
  TIMEZONES,
  IS_FOREIGN_COUNTRY,
  IN_AU,
  IN_CA,
  IN_UK,
  IN_EU,
  LOCALIZATION,
} from '../../utils/constants'
import {
  BogoContainer,
  DealTitle,
  DealDetails,
  Detail,
  ColorTitle,
  CheckContainer,
  HeadlineContainer,
  SaleBubble,
  Sale,
  Description,
  BogoCustomizationOption,
  DetailSpecifics,
} from './styles'
import { FormattedCurrency } from '../../utils/FormattedCurrency'

const Bogo = ({
  FRAME_VARIANTS,
  LIMITED_EDITION_VARIANTS,
  selectedSecondColor,
  handleSecondColorSelection,
  contentfulCTAOverrides,
  selected,
  setSelected,
  dealDetails,
  frame,
  handleOfferSelection,
}) => {
  const { check, nocheck } = useStaticQuery(graphql`
    query BogoQuery {
      check: file(relativePath: { eq: "icons/check-blue.png" }) {
        childImageSharp {
          gatsbyImageData(width: 20, height: 20, layout: FIXED, placeholder: NONE)
        }
      }
      nocheck: file(relativePath: { eq: "icons/no-check.png" }) {
        childImageSharp {
          gatsbyImageData(width: 20, height: 20, layout: FIXED, placeholder: NONE)
        }
      }
    }
  `)

  const FRAME_VARIANTS_ALL = frame ? [...FRAME_VARIANTS, ...LIMITED_EDITION_VARIANTS] : null

  const {
    dealTitle,
    description,
    offer,
    offerDescription,
    originalPrice,
    discountedPrice,
    sale,
    key,
  } = dealDetails

  const firstDeal = {
    'en-au': 'Take 15% Off Frames',
    'en-ca': 'Take 15% Off Frames',
    'en-eu': 'Take 20% Off All Frames',
    'en-gb': 'Take 15% Off Frames',
  }

  return (
    <BogoCustomizationOption frame={frame}>
      {(IN_AU || IN_CA || IN_UK || IN_EU) && <Subheader red>{firstDeal[LOCALIZATION]}</Subheader>}
      {dealTitle && (
        <Subheader blue>
          <Trans>{dealTitle}</Trans>
        </Subheader>
      )}
      {description && <Description dangerouslySetInnerHTML={{ __html: description }} />}
      <BogoContainer selected={selected}>
        <HeadlineContainer>
          <CheckContainer
            onClick={() => {
              if (frame) {
                setSelected(!selected)
              } else {
                handleOfferSelection(key, !selected)
              }
            }}
          >
            <GatsbyImage
              image={
                selected
                  ? check.childImageSharp.gatsbyImageData
                  : nocheck.childImageSharp.gatsbyImageData
              }
              alt={`${selected ? 'Checked BOGO Frame Deal' : 'BOGO Frame Deal Not Checked'}`}
            />
          </CheckContainer>
          <DealTitle>{offer}</DealTitle>
        </HeadlineContainer>
        <DealDetails>
          <Detail>
            {offerDescription}{' '}
            <DetailSpecifics gray>
              Was: <s>{FormattedCurrency(originalPrice)}</s>
            </DetailSpecifics>{' '}
            <DetailSpecifics red> Now: {FormattedCurrency(discountedPrice)} </DetailSpecifics>
          </Detail>
        </DealDetails>
        {selected && frame && !IS_FOREIGN_COUNTRY && (
          <>
            <ColorTitle>Select your second Frame color:</ColorTitle>
            <FrameProductOptions>
              {FRAME_VARIANTS_ALL.map((variant) => (
                <ColorSelectionItem
                  key={variant}
                  variant={variant}
                  onSelectColor={handleSecondColorSelection}
                  isOutOfStock={
                    contentfulCTAOverrides.edges[0].node[`en_10_frame_${variant}`] ===
                    'Out of Stock'
                  }
                  isSelected={selectedSecondColor === variant}
                  bogo
                  mediumRadio
                />
              ))}
            </FrameProductOptions>
          </>
        )}
        <SaleBubble>
          <Sale>{sale}</Sale>
        </SaleBubble>
      </BogoContainer>
    </BogoCustomizationOption>
  )
}

Bogo.propTypes = {
  FRAME_VARIANTS: PropTypes.array,
  LIMITED_EDITION_VARIANTS: PropTypes.array,
  selectedSecondColor: PropTypes.string,
  handleSecondColorSelection: PropTypes.func,
  contentfulCTAOverrides: PropTypes.object,
  selected: PropTypes.bool,
  setSelected: PropTypes.func,
  dealDetails: PropTypes.object,
  frame: PropTypes.bool,
  handleOfferSelection: PropTypes.func,
}

export default Bogo
